import React from 'react';
import Checkbox from './Checkbox';
import { encodeListValue, decodeListValue } from '../../lib/utilities';
import styles from './ListSelect.module.css';

const ALLOWED_MODES = [`single`, `multi`];
const ALLOWED_LAYOUTS = [`vertical`, `horizontal`];
const ALLOWED_BUTTON_TYPES = [`checkboxes`, `plain`, `icons`];
const OTHER_OPTION = {
	label: `Other`,
	value: `Other`,
	icon: `other.svg`,
	singleSelectOnly: false,
};
const SKIP_OPTION = {
	label: `Skip`,
	value: `Skip`,
	icon: `other.svg`,
	singleSelectOnly: true,
};

function prepareOptions(input, otherOption, skipOption) {
	const output = [...input];
	if (otherOption) output.push(OTHER_OPTION);
	if (skipOption) output.push(SKIP_OPTION);
	return output;
}

function isOptionSelected(option, selectedOptions) {
	return selectedOptions.includes(option.value);
}

function prepareSingleSelectValue(optValue, selectedOptions) {
	const list = new Set(selectedOptions);
	return (list.has(optValue) ? `` : optValue);
}

function prepareMultiSelectValue(optValue, selectedOptions, allOptions) {
	const list = new Set(selectedOptions);
	const optAlreadySelected = list.has(optValue);

	if (optAlreadySelected) {
		list.delete(optValue);
	} else {
		list.add(optValue);
	}

	allOptions.forEach(option => {
		if (option.value !== optValue && option.singleSelectOnly) list.delete(option.value);
	});

	return encodeListValue([...list].filter(item => item));
}

function handleListButtonClick(curOpt, selectedOptions, allOptions, mode, onChange) {
	switch (true) {
		case (mode === `single` || curOpt.singleSelectOnly):
			onChange(prepareSingleSelectValue(curOpt.value, selectedOptions));
			break;

		case (mode === `multi` && !curOpt.singleSelectOnly):
			onChange(prepareMultiSelectValue(curOpt.value, selectedOptions, allOptions));
			break;

		default:
			throw new Error(`Invalid mode "${mode}"`);
	}
}

function IconButton({ className, label, icon, isSelected, onClick }) {
	return (
		<div data-testid={`icon-btn-${label}`} className={`${styles.iconListSelectButton} ${isSelected && styles.iconListSelectButtonSelected} ${className}`} onClick={onClick}>
			<div className={styles.iconListSelectButtonIcon}><img src={`/assets/${icon}`} alt="Icon" /></div>
			<div className={styles.iconListSelectButtonLabel}><span>{label}</span></div>
		</div>
	);
}

function SelectOption({ options, mode, layout, buttonType, onChange, selectedOptions, curOpt }) {
	const isSelected = isOptionSelected(curOpt, selectedOptions);
	const onClick = () => handleListButtonClick(curOpt, selectedOptions, options, mode, onChange);

	if (buttonType === `icons`) {
		return (
			<IconButton
				label={curOpt.label}
				icon={curOpt.icon}
				isSelected={isSelected}
				onClick={onClick}
			/>
		);
	} else {
		const listSelectButtonLayoutCls = `listSelectButton${layout[0].toUpperCase()}${layout.substr(1)}`;
		const hideCheckboxes = (buttonType === `plain`);
		return (
			<Checkbox
				className={`${styles.listSelectButton} ${styles[listSelectButtonLayoutCls]}`}
				label={curOpt.label}
				hideCheckboxes={hideCheckboxes}
				isChecked={isSelected}
				onClick={onClick}
			/>
		);
	}
}

function ListSelect({
	className, options, value, mode = `single`, layout = `vertical`, buttonType = `checkboxes`, otherOption = false, skipOption = false, onChange,
}) {
	if (!ALLOWED_MODES.includes(mode)) throw new Error(`Invalid mode "${mode}", must be one of "${ALLOWED_MODES}"`);
	if (!ALLOWED_LAYOUTS.includes(layout)) throw new Error(`Invalid layout "${layout}", must be one of "${ALLOWED_LAYOUTS}"`);
	if (!ALLOWED_BUTTON_TYPES.includes(buttonType)) throw new Error(`Invalid button type "${buttonType}", must be one of "${ALLOWED_BUTTON_TYPES}"`);

	const useOptions = prepareOptions(options, otherOption, skipOption);
	const selectedOptions = decodeListValue(value);
	let clsOuter;

	if (buttonType === `icons`) {
		clsOuter = `${styles.iconListSelect} ${className}`;
	} else {
		const listSelectLayoutCls = `listSelect${layout[0].toUpperCase()}${layout.substr(1)}`;
		clsOuter = `${styles.listSelect} ${styles[listSelectLayoutCls]} ${className}`;
	}

	return (
		<div className={clsOuter} data-testid="listSelect">
			{useOptions.map((curOpt) =>
				<SelectOption
					key={curOpt.value}
					options={useOptions}
					mode={mode}
					layout={layout}
					buttonType={buttonType}
					onChange={onChange}
					selectedOptions={selectedOptions}
					curOpt={curOpt}
				/>
			)}
		</div>
	);
}

export default ListSelect;
