import React, { useRef, useEffect } from 'react';
import { connectToStore } from '../store';
import items from './items';
import Toolbar from './Toolbar';
import styles from './ActiveItem.module.css';

const useScroll = () => {
	const refEl = useRef(null);
	const executeScroll = () => refEl.current.scrollTo({ top: 0 });
	return [executeScroll, refEl];
};

function Questions({ state, scrollQuestionToTop }) {
	const { activeItem } = state;
	const ItemCmp = items[activeItem.itemKey];
	const [executeScroll, htmlElRef] = useScroll();

	if (!ItemCmp) throw new Error(`The item component cannot be undefined!`);

	useEffect(executeScroll, [activeItem.itemKey]);

	return (
		<div ref={htmlElRef} className={styles.questions}>
			{/* {isQuestion && <QuestionNumber questionNum={activeItem.questionNum} />} */}
			<ItemCmp scrollQuestionToTop={scrollQuestionToTop} />
		</div>
	);
}

function ActiveItem({ state, actions }) {
	return (
		<div className={styles.activeItem}>
			<Questions state={state} actions={actions} />
			<Toolbar />
		</div>
	);
}

export default connectToStore(ActiveItem);
