import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionStressCauses.module.css';

export const OPTIONS = [
	{
		label: 'Battling an Addiction',
		value: 'Battling an Addiction',
		icon: 'struggling-with-addiction.svg',
	},
	{
		label: 'Becoming a Parent',
		value: 'Becoming a Parent',
		icon: 'becoming-a-parent.svg',
	},
	{
		label: 'Bereavement',
		value: 'Bereavement',
		icon: 'bereavement.svg',
	},
	{
		label: 'Bullying or Abuse',
		value: 'Bullying or Abuse',
		icon: 'bullying-or-abuse.svg',
	},
	{
		label: 'Coronavirus',
		value: 'Coronavirus',
		icon: 'coronavirus.svg',
	},
	{
		label: 'Family Conflict',
		value: 'Family Conflict',
		icon: 'family-problems.svg',
	},
	{
		label: 'Financial Concerns',
		value: 'Financial Concerns',
		icon: 'financial-concerns.svg',
	},
	{
		label: 'Housing Problems',
		value: 'Housing Problems',
		icon: 'poor-living-standards.svg',
	},
	{
		label: 'Illness or Injury',
		value: 'Illness or Injury',
		icon: 'illness-or-injury.svg',
	},
	{
		label: 'Marital Problems',
		value: 'Marital Problems',
		icon: 'martial-problems.svg',
	},
	{
		label: 'Relationship Problems',
		value: 'Relationship Problems',
		icon: 'relationship-problems.svg',
	},
	{
		label: 'Trauma or Accident',
		value: 'Trauma or Accident',
		icon: 'trauma.svg',
	},
	{
		label: 'University Pressure',
		value: 'University Pressure',
		icon: 'university-pressure.svg',
	},
	{
		label: 'Work Pressure',
		value: 'Work Pressure',
		icon: 'work-pressures.svg',
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.stress.causes`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.stress.causes.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionStressCauses({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="Don't worry, it's not unusual to feel stressed and overwhelmed. What is making you feel this way? Please be honest as this will help us find you the best therapy option to suit your needs."
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					buttonType="icons"
					value={formData[`mood.stress.causes`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.stress.causes.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionStressCauses.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionStressCauses.getNextItemKey = (formData) => {
	return (formData[`mood.stress.causes`] ? `QuestionStressEmotions` : undefined);
};

QuestionStressCauses.getAllowSubmit = () => false;

export default connectToStore(QuestionStressCauses);
