import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionAnxietyEmotions.module.css';

export const OPTIONS = [
	{
		label: `Angry`,
		value: `Angry`,
		icon: `angry.svg`,
	},
	{
		label: `Anxious`,
		value: `Anxious`,
		icon: `anxious.svg`,
	},
	{
		label: `Avoiding Socialising`,
		value: `Avoiding Socialising`,
		icon: `avoid-socialising.svg`,
	},
	{
		label: `Dizzy or Nauseous`,
		value: `Dizzy or Nauseous`,
		icon: `dizzy.svg`,
	},
	{
		label: `Exhaustion`,
		value: `Exhaustion`,
		icon: `exhausted.svg`,
	},
	{
		label: `Irritable and Restless`,
		value: `Irritable and Restless`,
		icon: `irritable.svg`,
	},
	{
		label: `Negative Thoughts`,
		value: `Negative Thoughts`,
		icon: `negative-thoughts.svg`,
	},
	/*
	{
		label: 'Suicidal thoughts',
		value: 'Suicidal thoughts',
		icon: 'suicide.svg',
	},
	*/
	{
		label: `Overwhelmed`,
		value: `Overwhelmed`,
		icon: `overwhelmed.svg`,
	},
	{
		label: `Problems Concentrating`,
		value: `Problems Concentrating`,
		icon: `problems-concentrating.svg`,
	},
	{
		label: `Racing Heart`,
		value: `Racing Heart`,
		icon: `racing-heart.svg`,
	},
	{
		label: `Trouble Sleeping`,
		value: `Trouble Sleeping`,
		icon: `sleepy.svg`,
	},
	{
		label: `Unable to make Decisions`,
		value: `Unable to make Decisions`,
		icon: `unable-to-make-decisions.svg`,
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.anxiety.emotions`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.anxiety.emotions.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionAnxietyEmotions({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What other feelings, emotions, or behaviours have you been experiencing in addition to nervousness, worry and concern?"
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					buttonType="icons"
					value={formData[`mood.anxiety.emotions`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.anxiety.emotions.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionAnxietyEmotions.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionAnxietyEmotions.getNextItemKey = (formData) => {
	return (formData[`mood.anxiety.emotions`] ? `QuestionHealthAffected` : undefined);
};

QuestionAnxietyEmotions.getAllowSubmit = () => false;

export default connectToStore(QuestionAnxietyEmotions);
