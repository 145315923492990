import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionPractitionersLanguage.module.css';

export const OPTIONS = [
    { label: `No preference`, value: `NONE` },
    // get languages values from the app (/src/utils/languages.js file)
    { label: `English`, value: `en` },
    { label: `French`, value: `fr` },
    { label: `German`, value: `de` },
    { label: `Greek`, value: `el` },
    { label: `Italian`, value: `it` },
    { label: `Chinese`, value: `zh` },
    { label: `Russian`, value: `ru` },
    { label: `Spanish`, value: `es` },
    { label: `Turkish`, value: `tr` },
    { label: `Ukrainian`, value: `uk` },
];

function updateAnswer(actions, activeItem, formData, newValue) {
    formData[`practitioner.language`] = newValue;
    actions.updateFormData(formData);
    actions.goToNextItem(activeItem, formData);
}

function QuestionPractitionersLanguage({ state, actions }) {
    const { activeItem, formData } = state;

    return (
        <section className={styles.question}>
            <article>
                <QuestionCopy
                    questionNum={activeItem.questionNum}
                    title="Would you like to speak to someone in a specific language? *"
                    description="We will try our best to match you with therapists that speak your language."
                />
                <ListSelect
                    mode="single"
                    layout="vertical"
                    buttonType="checkboxes"
                    value={formData[`practitioner.language`]}
                    options={OPTIONS}
                    onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue)}
                />
            </article>
        </section>
    );
}

QuestionPractitionersLanguage.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionPractitionersLanguage.getNextItemKey = (formData) => {
    return (formData[`practitioner.language`] ? `QuestionEmployerBenefit` : undefined);
};

QuestionPractitionersLanguage.getAllowSubmit = () => false;

export default connectToStore(QuestionPractitionersLanguage);
