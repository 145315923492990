import React from 'react';
import QuestionNumber from './QuestionNumber';
import styles from './QuestionCopy.module.css';

function QuestionCopy({ className, questionNum, title, description }) {
	return (
		<div className={`${styles.questionCopy} ${className}`}>
			<div className={styles.title}>
				{questionNum ? <QuestionNumber className={styles.questionNumber} questionNum={questionNum} /> : <></>}
				<span>{title}</span>
			</div>
			<div className={styles.description}>{description}</div>
		</div>
	);
}

export default QuestionCopy;
