import React from 'react';
import styles from './Input.module.css';

function handleReturnKey(evt, onReturnKey) {
	if (!onReturnKey) return;
	if (evt.keyCode !== 13) return;
	onReturnKey();
}

function Input({ className, type, name, value, placeholder, onChange, onReturnKey }) {
	return (
		<div className={`${styles.inputContainer} ${className}`}>
			<input data-testid={`${name}-input`} className={styles.inputElement} type={type} name={name} value={value} placeholder={placeholder} onChange={onChange} onKeyUp={(evt) => handleReturnKey(evt, onReturnKey)} />
		</div>
	);
}

export default Input;
