import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import Input from '../common/Input';
import { extractPrevItemKeyFromPath, formatPhoneNumber } from '../../lib/utilities';
import styles from './QuestionIndividualsPhone.module.css';

function updateAnswer(actions, formData, evt) {
	formData[`individual.phone`] = formatPhoneNumber(evt.target.value);
	actions.updateFormData(formData);
}

function QuestionIndividualsPhone({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What is your phone number? *"
					description="We have a duty of care towards you and take our community's safety very seriously. We require your phone number in the unlikely case that we need to contact you in an emergency to signpost crisis support."
				/>
				<Input
					value={formData[`individual.phone`]}
					type="tel"
					placeholder="Type your phone number here..."
					onChange={(evt) => updateAnswer(actions, formData, evt)}
					onReturnKey={() => actions.goToNextItem(activeItem, formData)}
				/>
			</article>
		</section>
	);
}

QuestionIndividualsPhone.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionIndividualsPhone.getNextItemKey = (formData) => {
	return (formData[`individual.phone`].trim() ? `QuestionIndividualsGender` : undefined);
};

QuestionIndividualsPhone.getAllowSubmit = () => false;

export default connectToStore(QuestionIndividualsPhone);
