import { getPrevItemKey, getNextItemKey } from '../../lib/utilities';

function goToPrevItem(activeItem, formData) {
	const newItemKey = getPrevItemKey(activeItem, formData);
	if (!newItemKey) return undefined;

	return {
		type: `GO_PREV`,
		payload: { newItemKey },
	};
}

function goToNextItem(activeItem, formData) {
	const newItemKey = getNextItemKey(activeItem, formData);
	if (!newItemKey) return undefined;

	return {
		type: `GO_NEXT`,
		payload: { newItemKey },
	};
}

const setLoading = (value) => ({
	type: `SET_LOADING`,
	payload: value
})

export default {
	goToPrevItem,
	goToNextItem,
	setLoading
};
