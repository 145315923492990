import React from 'react';
import styles from './Button.module.css';

function Button({ className, invert, disabled, label, onClick }) {
	const clickHandler = (!disabled ? onClick : () => null);

	return (
		<button className={`${styles.button} ${invert && styles.buttonInverted} ${disabled && styles.buttonDisabled} ${className}`} onClick={clickHandler}>
			<span>{label}</span>
		</button>
	);
}

export default Button;
