import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionGeneralMood.module.css';

export const OPTIONS = [
	{
		label: `Nervous, Worried or Concerned`,
		value: `nervous`,
		icon: `anxious.svg`,
	},
	{
		label: `Sad, lethargic or lacking motivation`,
		value: `lethargic`,
		icon: `sad-and-unhappy.svg`,
	},
	{
		label: `Stressed and Overwhelmed`,
		value: `stressed`,
		icon: `stressed.svg`,
	},
	{
		label: `Struggling with an Addiction`,
		value: `addiction`,
		icon: `struggling-with-addiction.svg`,
	},
	{
		label: `I want to grow without therapy`,
		value: `feelingGreat`,
		icon: `happy.svg`,
	},
];

function updateAnswer(actions, activeItem, formData, newValue) {
	formData[`mood.general`] = newValue;
	actions.updateFormData(formData);
	if (newValue !== `feelingGreat`) actions.goToNextItem(activeItem, formData);
}

function QuestionGeneralMood({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="How have you been feeling generally over the last two weeks? *"
				/>
				<ListSelect
					mode="single"
					buttonType="icons"
					value={formData[`mood.general`]}
					options={OPTIONS}
					onChange={newValue => updateAnswer(actions, activeItem, formData, newValue)}
				/>
			</article>
		</section>
	);
}

QuestionGeneralMood.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionGeneralMood.getNextItemKey = formData => {
	switch (formData[`mood.general`]) {
		case `nervous`:
			return `QuestionAnxietyCauses`;
		case `lethargic`:
			return `QuestionDepressionCauses`;
		case `stressed`:
			return `QuestionStressCauses`;
		case `addiction`:
			return `QuestionAddictionTypes`;
		case `feelingGreat`:
			return undefined;
		default:
			return undefined;
	}
};

QuestionGeneralMood.getAllowSubmit = formData => {
	return formData[`mood.general`] === `feelingGreat`;
};

export default connectToStore(QuestionGeneralMood);
