import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionIndividualsGender.module.css';

export const OPTIONS = [
	{ label: `Female`, value: `female` },
	{ label: `Male`, value: `male` },
	{ label: `Other`, value: `other` },
	{ label: `Non-conforming`, value: `non-conforming` },
	{ label: `Prefer not to say`, value: `prefer not to say` },
];

function updateAnswer(actions, activeItem, formData, newValue) {
	formData[`individual.gender`] = newValue;
	actions.updateFormData(formData);
	actions.goToNextItem(activeItem, formData);
}

function QuestionIndividualsGender({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What is your gender? *"
					description="we need this to match you with coaches and therapists."
				/>
				<ListSelect
					mode="single"
					layout="vertical"
					buttonType="checkboxes"
					value={formData[`individual.gender`]}
					options={OPTIONS}
					onChange={newValue => updateAnswer(actions, activeItem, formData, newValue)}
				/>
			</article>
		</section>
	);
}

QuestionIndividualsGender.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionIndividualsGender.getNextItemKey = formData => {
	return formData[`individual.gender`] ? `QuestionIndividualsCountry` : undefined;
};

QuestionIndividualsGender.getAllowSubmit = () => false;

export default connectToStore(QuestionIndividualsGender);
