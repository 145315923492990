import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import Input from '../common/Input';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionEmployerName.module.css';

function updateAnswer(actions, formData, evt) {
	formData[`employer.name`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionEmployerName({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What is the name of your employer? *"
					description="This is needed to make sure payments for therapy or coaching sessions don't come your way! Rest assured, we won't tell your employer that you are using InsideOut."
				/>
				<Input
					value={formData[`employer.name`]}
					type="text"
					placeholder="Type your answer here..."
					onChange={(evt) => updateAnswer(actions, formData, evt)}
					onReturnKey={() => actions.goToNextItem(activeItem, formData)}
				/>
			</article>
		</section>
	);
}

QuestionEmployerName.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionEmployerName.getNextItemKey = (formData) => {
	return (formData[`employer.name`].trim() ? `QuestionGeneralMood` : undefined);
};

QuestionEmployerName.getAllowSubmit = () => false;

export default connectToStore(QuestionEmployerName);
