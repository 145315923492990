import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionDepressionEmotions.module.css';

export const OPTIONS = [
	{
		label: `Angry`,
		value: `Angry`,
		icon: `angry.svg`,
	},
	{
		label: `Anxiety`,
		value: `Anxiety`,
		icon: `anxious.svg`,
	},
	/*
	{
		label: `Belief others are better off without me`,
		value: `Belief others are better off without me`,
		icon: `belief-others-are-better-without-you.svg`,
	},
	*/
	{
		label: `Compulsive Behaviours`,
		value: `Compulsive Behaviours`,
		icon: `ocd.svg`,
	},
	{
		label: `Exhaustion`,
		value: `Exhaustion`,
		icon: `exhausted.svg`,
	},
	{
		label: `Feeling Criticised`,
		value: `Feeling Criticised`,
		icon: `feeling-criticised.svg`,
	},
	{
		label: `Feeling Guilty`,
		value: `Feeling Guilty`,
		icon: `feeling-guilty.svg`,
	},
	{
		label: `Feeling Hopeless`,
		value: `Feeling Hopeless`,
		icon: `hopeless-and-fed-up.svg`,
	},
	{
		label: `Frequent Crying`,
		value: `Frequent Crying`,
		icon: `sad-and-tearful.svg`,
	},
	{
		label: `Loss of friends and interests`,
		value: `Loss of friends and interests`,
		icon: `loss-of-interest-in-friends-and-hobbies.svg`,
	},
	{
		label: `Loss of Libido`,
		value: `Loss of Libido`,
		icon: `loss-of-labido.svg`,
	},
	{
		label: `Low Self Confidence`,
		value: `Low Self Confidence`,
		icon: `low-self-esteem-and-confidence.svg`,
	},
	{
		label: `Negative Thoughts`,
		value: `Negative Thoughts`,
		icon: `negative-thoughts.svg`,
	},
	/*
	{
		label: 'Suicidal thoughts',
		value: 'Suicidal thoughts',
		icon: 'suicide.svg',
	},
	*/
	{
		label: `Problems Sleeping`,
		value: `Problems Sleeping`,
		icon: `sleepy.svg`,
	},
	{
		label: `Self Blame`,
		value: `Self Blame`,
		icon: `self-blame.svg`,
	},
	{
		label: `Self Harming`,
		value: `Self Harming`,
		icon: `self-harm.svg`,
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.depression.emotions`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.depression.emotions.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionDepressionEmotions({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What other feelings, emotions, or behaviours have you been experiencing in addition to sadness?"
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					buttonType="icons"
					value={formData[`mood.depression.emotions`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.depression.emotions.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionDepressionEmotions.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionDepressionEmotions.getNextItemKey = (formData) => {
	return (formData[`mood.depression.emotions`] ? `QuestionHealthAffected` : undefined);
};

QuestionDepressionEmotions.getAllowSubmit = () => false;

export default connectToStore(QuestionDepressionEmotions);
