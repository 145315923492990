import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import Input from '../common/Input';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionIndividualsPhone.module.css';

function updateAnswer(actions, formData, evt) {
	formData[`individual.gp`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionIndividualsGP({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="Please provide your GP contact details: practice name, address, and phone number. *"
					description="We require your GP contact details so we can alert in the event of an emergency."
				/>
				<Input
					value={formData[`individual.gp`]}
					type="text"
					placeholder="Type your answer here..."
					onChange={(evt) => updateAnswer(actions, formData, evt)}
					onReturnKey={() => actions.goToNextItem(activeItem, formData)}
				/>
			</article>
		</section>
	);
}

QuestionIndividualsGP.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionIndividualsGP.getNextItemKey = (formData) => {
	const gpInfo = formData[`individual.gp`].trim();
	return (gpInfo &&  gpInfo.length > 4 ? `QuestionEmergencyContactName` : undefined);
};

QuestionIndividualsGP.getAllowSubmit = () => false;

export default connectToStore(QuestionIndividualsGP);
