import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import Input from '../common/Input';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionIndividualsEmail.module.css';

function updateAnswer(actions, formData, evt) {
	formData[`individual.email`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionIndividualsEmail({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What is your email address? *"
					description="We need this so to connect you with a coach or therapist if needed."
				/>
				<Input
					value={formData[`individual.email`]}
					type="email"
					placeholder="Type your email here..."
					onChange={(evt) => updateAnswer(actions, formData, evt)}
					onReturnKey={() => actions.goToNextItem(activeItem, formData)}
				/>
			</article>
		</section>
	);
}

QuestionIndividualsEmail.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionIndividualsEmail.getNextItemKey = (formData) => {
	return (/^.+@.+\..+$/.test(formData[`individual.email`]) ? `QuestionIndividualsPhone` : undefined);
};

QuestionIndividualsEmail.getAllowSubmit = () => false;

export default connectToStore(QuestionIndividualsEmail);
