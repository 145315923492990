import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionAddictionEmotions.module.css';

export const OPTIONS = [
	{
		label: 'Anger',
		value: 'Anger',
		icon: '',
	},
	{
		label: 'Anxious',
		value: 'Anxious',
		icon: '',
	},
	{
		label: 'Avoiding socialising',
		value: 'Avoiding socialising',
		icon: '',
	},
	{
		label: 'Feelings of detachment and numbness',
		value: 'Feelings of detachment and numbness',
		icon: '',
	},
	{
		label: 'Negative thoughts',
		value: 'Negative thoughts',
		icon: '',
	},
	/*
	{
		label: 'Suicidal thoughts',
		value: 'Suicidal thoughts',
		icon: '',
	},
	*/
	{
		label: 'Hopelessness and guilt',
		value: 'Hopelessness and guilt',
		icon: '',
	},
	{
		label: 'Overwhelmed and unable to cope',
		value: 'Overwhelmed and unable to cope',
		icon: '',
	},
	{
		label: 'Problems concentrating',
		value: 'Problems concentrating',
		icon: '',
	},
	{
		label: 'Sadness and frequent crying',
		value: 'Sadness and frequent crying',
		icon: '',
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.addiction.emotions`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.addiction.emotions.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionAddictionEmotions({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="It's not unusual to develop an addiction. What other feelings, emotions or behaviors have you been experiencing?"
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					layout="vertical"
					buttonType="checkboxes"
					value={formData[`mood.addiction.emotions`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.addiction.emotions.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionAddictionEmotions.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionAddictionEmotions.getNextItemKey = (formData) => {
	return (formData[`mood.addiction.emotions`] ? `QuestionHealthAffected` : undefined);
};

QuestionAddictionEmotions.getAllowSubmit = () => false;

export default connectToStore(QuestionAddictionEmotions);
