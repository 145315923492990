import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { polyfill as polyfillSmoothScrolling } from './lib/smoothscroll';
import React from 'react';
import { render } from 'react-snapshot';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { rootReducer } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

polyfillSmoothScrolling();

const store = createStore(rootReducer);

render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
