import { combineReducers } from 'redux';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from './mappers';
import activeItem from './activeItem';
import formData from './formData';

export const rootReducer = combineReducers({
	activeItem: activeItem.reducer,
	formData: formData.reducer,
});

export const actions = {
	...activeItem.actions,
	...formData.actions,
};

export function connectToStore(Component) {
	const wrap = connect(
		(state) => mapStateToProps(state),
		(dispatch) => mapDispatchToProps(dispatch, actions)
	);
	return wrap(Component);
}
