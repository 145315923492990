import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionEmployerBenefit.module.css';

export const OPTIONS = [
	{ label: `Yes`, value: `yes` },
	{ label: `No`, value: `no` },
	{ label: `Don't know`, value: `unknown` },
];

function updateAnswer(actions, activeItem, formData, newValue) {
	formData[`employer.benefit`] = newValue;
	actions.updateFormData(formData);
	actions.goToNextItem(activeItem, formData);
}

function QuestionEmployerBenefit({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="Does your employer offer InsideOut as an Employee Benefit? *"
				/>
				<ListSelect
					mode="single"
					layout="vertical"
					buttonType="checkboxes"
					value={formData[`employer.benefit`]}
					options={OPTIONS}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue)}
				/>
			</article>
		</section>
	);
}

QuestionEmployerBenefit.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionEmployerBenefit.getNextItemKey = (formData) => {
	switch (formData[`employer.benefit`]) {
		case `yes`: return `QuestionEmployerName`;
		case `no`: return `QuestionGeneralMood`;
		case `unknown`: return `QuestionGeneralMood`;
		default: return undefined;
	}
};

QuestionEmployerBenefit.getAllowSubmit = () => false;

export default connectToStore(QuestionEmployerBenefit);
