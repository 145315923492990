import React from 'react';
import { connectToStore } from '../store';
import { submitForm } from '../lib/formSubmission';
import { canGoPrev, canGoNext, canSubmitForm } from '../lib/utilities';
import Button from './common/Button';
import styles from './Toolbar.module.css';

const handleSubmit = async (state, actions) => {
	const { activeItem, formData } = state;
	actions.setLoading(true)
	const success = await submitForm(activeItem, formData, actions)
	if (!success) {
		actions.setLoading(false)
	}
}

function Toolbar({ state, actions }) {
	const { activeItem, formData } = state;
	const prev = canGoPrev(activeItem, formData);
	const next = canGoNext(activeItem, formData);
	const submit = canSubmitForm(activeItem, formData);
	const isFormComplete = formData[`meta.completed`];
	const showNextBtn = (!submit && !isFormComplete);
	const showSubmitBtn = (submit || isFormComplete);

	return (
		<div className={styles.toolbar}>
			<div className={styles.inner}>
				<Button className={styles.btn} invert={true} disabled={!prev} label="Previous" onClick={() => actions.goToPrevItem(activeItem, formData)} />
				{showNextBtn && <Button className={styles.btn} invert={true} disabled={!next} label="Next" onClick={() => actions.goToNextItem(activeItem, formData)} />}
				{showSubmitBtn && <Button className={`${styles.btn} ${styles.submitBtn}`} invert={true} disabled={!submit} label="Submit" onClick={() => handleSubmit(state, actions)} />}
				{activeItem.loading && <div className={styles.loader}>Loading...</div>}
			</div>
		</div>
	);
}

export default connectToStore(Toolbar);
