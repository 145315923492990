import React from 'react';
import { connectToStore } from '../../store';
import QuoteMark from '../common/QuoteMark';
import styles from './BlurbIntroduction.module.css';

function BlurbIntroduction() {
	return (
		<section className={styles.blurb}>
			<article>
				<p>
					<QuoteMark /> Hi there!
				</p>
				<p>I'm Becky, Chief Therapist at InsideOut.</p>
				<p>
					It might not seem like it yet but you’ve just taken a big step forward in making yourself happier and
					stronger. Reaching out for support can be scary but there is no need to worry, we are here to support you
					every step of the way!
				</p>
				<p>
					Before we get started, we need a little information so we can find the best support options for you. There are
					no right or wrong answers, in fact the more honest you are, the better! Your responses to the following
					questions are strictly confidential - we promise not to share them with anyone.
				</p>
				<p>
					Remember it's OK to not be OK. <QuoteMark />
				</p>
				<p>Sending happy vibes,</p>
				<p>Becky</p>
			</article>
		</section>
	);
}

BlurbIntroduction.getPrevItemKey = () => null;

BlurbIntroduction.getNextItemKey = () => `BlurbConsent`;

BlurbIntroduction.getAllowSubmit = () => false;

export default connectToStore(BlurbIntroduction);
