import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionPractitionersCulture.module.css';

export const OPTIONS = [
    { label: `No preference`, value: `NONE` },
    { label: `Arab`, value: `ARAB` },
    { label: `Asian`, value: `ASIAN` },
    { label: `Asian Pacific`, value: `ASIAN_PACIFIC` },
    { label: `Black African`, value: `BLACK_AFRICAN` },
    { label: `Black Caribbean`, value: `BLACK_CARIBBEAN` },
    { label: `White`, value: `WHITE` },
];

function updateAnswer(actions, activeItem, formData, newValue) {
    formData[`practitioner.culture`] = newValue;
    actions.updateFormData(formData);
    actions.goToNextItem(activeItem, formData);
}

function QuestionPractitionersCulture({ state, actions }) {
    const { activeItem, formData } = state;

    return (
        <section className={styles.question}>
            <article>
                <QuestionCopy
                    questionNum={activeItem.questionNum}
                    title="Would you like to speak to someone from a specific cultural background? *"
                    description="We need this to provide you with relevant matches."
                />
                <ListSelect
                    mode="single"
                    layout="vertical"
                    buttonType="checkboxes"
                    value={formData[`practitioner.culture`]}
                    options={OPTIONS}
                    onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue)}
                />
            </article>
        </section>
    );
}

QuestionPractitionersCulture.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionPractitionersCulture.getNextItemKey = (formData) => {
    return (formData[`practitioner.culture`] ? `QuestionPractitionersLanguage` : undefined);
};

QuestionPractitionersCulture.getAllowSubmit = () => false;

export default connectToStore(QuestionPractitionersCulture);
