import BlurbIntroduction from './BlurbIntroduction';
import BlurbConsent from './BlurbConsent';
import QuestionIndividualsEmail from './QuestionIndividualsEmail';
import QuestionIndividualsPhone from './QuestionIndividualsPhone';
import QuestionIndividualsGender from './QuestionIndividualsGender';
import QuestionIndividualsCountry from './QuestionIndividualsCountry';
import QuestionIndividualsUsState from './QuestionIndividualsUsState';
import QuestionEmergencyContactName from './QuestionEmergencyContactName';
import QuestionEmergencyContactPhone from './QuestionEmergencyContactPhone';
import QuestionPractitionersGender from './QuestionPractitionersGender';
import QuestionPractitionersCulture from './QuestionPractitionersCulture';
import QuestionPractitionersLanguage from './QuestionPractitionersLanguage';
import QuestionEmployerBenefit from './QuestionEmployerBenefit';
import QuestionEmployerName from './QuestionEmployerName';
import QuestionGeneralMood from './QuestionGeneralMood';
import QuestionAnxietyCauses from './QuestionAnxietyCauses';
import QuestionAnxietyEmotions from './QuestionAnxietyEmotions.js';
import QuestionDepressionCauses from './QuestionDepressionCauses.js';
import QuestionDepressionEmotions from './QuestionDepressionEmotions.js';
import QuestionStressCauses from './QuestionStressCauses.js';
import QuestionStressEmotions from './QuestionStressEmotions.js';
import QuestionAddictionTypes from './QuestionAddictionTypes.js';
import QuestionAddictionCauses from './QuestionAddictionCauses.js';
import QuestionAddictionEmotions from './QuestionAddictionEmotions.js';
import QuestionHealthAffected from './QuestionHealthAffected';
import QuestionWorkAffected from './QuestionWorkAffected';
import QuestionRelationshipsAffected from './QuestionRelationshipsAffected';
import QuestionIndividualsGP from './QuestionIndividualsGP';

export default {
	BlurbIntroduction,
	BlurbConsent,
	QuestionIndividualsEmail,
	QuestionIndividualsPhone,
	QuestionIndividualsGender,
	QuestionIndividualsCountry,
	QuestionIndividualsUsState,
	QuestionEmergencyContactName,
	QuestionEmergencyContactPhone,
	QuestionPractitionersGender,
	QuestionPractitionersCulture,
	QuestionPractitionersLanguage,
	QuestionEmployerBenefit,
	QuestionEmployerName,
	QuestionGeneralMood,
	QuestionAnxietyCauses,
	QuestionAnxietyEmotions,
	QuestionDepressionCauses,
	QuestionDepressionEmotions,
	QuestionStressCauses,
	QuestionStressEmotions,
	QuestionAddictionTypes,
	QuestionAddictionCauses,
	QuestionAddictionEmotions,
	QuestionHealthAffected,
	QuestionWorkAffected,
	QuestionRelationshipsAffected,
	QuestionIndividualsGP,
};
