import queryString from 'query-string';
import items from '../components/items';
import axios from 'axios';
import { API_URL } from './formSubmission';

const LIST_VALUE_DELIMITER = `;`;

export function getUserId(search = window.location.search) {
	return queryString.parse(search).userId || null;
}

export function getAccessToken(search = window.location.search) {
	return queryString.parse(search).accessToken || null;
}

export function encodeListValue(input) {
	return input.join(LIST_VALUE_DELIMITER);
}

export function decodeListValue(input) {
	return input.split(LIST_VALUE_DELIMITER);
}

export function listValueContainsOther(input) {
	const items = decodeListValue(input);
	const found = items.find(item => /^other$/i.test(item));
	return Boolean(found);
}

export function isQuestionItem(itemKey) {
	return /^question/i.test(itemKey);
}

export function getPrevItemKey(activeItem, formData) {
	if (formData[`meta.completed`]) return undefined;

	const ItemCmp = items[activeItem.itemKey];
	if (!ItemCmp) return undefined;

	const newItemKey = ItemCmp.getPrevItemKey(formData, activeItem.itemPath);
	if (!newItemKey) return undefined;

	return newItemKey;
}

export function canGoPrev(activeItem, formData) {
	return Boolean(getPrevItemKey(activeItem, formData));
}

export function getNextItemKey(activeItem, formData) {
	if (formData[`meta.completed`]) return undefined;

	const ItemCmp = items[activeItem.itemKey];
	if (!ItemCmp) return undefined;

	const newItemKey = ItemCmp.getNextItemKey(formData, activeItem.itemPath);
	if (!newItemKey) return undefined;

	return newItemKey;
}

export function canGoNext(activeItem, formData) {
	return Boolean(getNextItemKey(activeItem, formData));
}

export function getAllowSubmit(activeItem, formData) {
	if (formData[`meta.completed`]) return false;

	const ItemCmp = items[activeItem.itemKey];
	if (!ItemCmp) return false;

	return ItemCmp.getAllowSubmit(formData, activeItem.itemPath);
}

export function canSubmitForm(activeItem, formData) {
	return Boolean(getAllowSubmit(activeItem, formData));
}

export function extractPrevItemKeyFromPath(itemPath) {
	if (itemPath.length <= 1) return undefined;
	return itemPath[itemPath.length - 2];
}

export function formatPhoneNumber(input) {
	return input.replace(/[^\d+]/g, ``).replace(/(\d)\+/g, `$1`);
}

export function filterEmptyArrayElements(input) {
	return input.filter(item => item);
}

export async function sendDiagnosticStartedAction(data, correlation_id) {
	const opts = {
		baseURL: API_URL,
		headers: {
			'x-correlation-id': correlation_id,
			'authorization': `Bearer ${getAccessToken()}`,
		},
		validateStatus: null,
	};
	let req;

	try {
		req = await axios.create(opts).post(`diagnosticForm/startedAction`, data);
		if (req.status !== 200) {
			return [false, req && req.data && req.data.error ? req.data.error.error_message : `Unknown error`];
		}
	} catch (err) {
		return [false, err.message];
	}

	return [true];
}
