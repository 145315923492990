import { getUserId, isQuestionItem } from '../../lib/utilities';

const FIRST_ITEM_KEY = (getUserId() ? `BlurbConsent` : `BlurbIntroduction`);
const INITIAL_STATE = {
	itemKey: FIRST_ITEM_KEY,
	itemPath: [FIRST_ITEM_KEY],
	questionNum: 0,
	loading: false
};

function calculateQuestionNum(itemPath) {
	return itemPath.reduce((acc, item) => (isQuestionItem(item) ? acc + 1 : acc), 0);
}

function goPrev(state, newItemKey) {
	const newItemPath = state.itemPath.slice(0, -1);
	const newQuestionNum = calculateQuestionNum(newItemPath);

	return {
		...state,
		itemKey: newItemKey,
		itemPath: newItemPath,
		questionNum: newQuestionNum,
	};
}

function goNext(state, newItemKey) {
	const newItemPath = [...state.itemPath, newItemKey];
	const newQuestionNum = calculateQuestionNum(newItemPath);

	return {
		...state,
		itemKey: newItemKey,
		itemPath: newItemPath,
		questionNum: newQuestionNum,
	};
}

const setLoading = (state, payload) =>({
	...state,
	loading: payload
})


export default function reducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;
	const { newItemKey } = payload || {};

	switch (type) {
		case 'GO_PREV':
			return goPrev(state, newItemKey);
		case 'GO_NEXT':
			return goNext(state, newItemKey);
		case 'SET_LOADING':
			return setLoading(state, payload);
		default:
			return state;
	}
}
