import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionStressEmotions.module.css';

export const OPTIONS = [
	{
		label: `Angry, Annoyed or Frustrated`,
		value: `Angry, Annoyed or Frustrated`,
		icon: `angry.svg`,
	},
	{
		label: `Compulsive Behaviours`,
		value: `Compulsive Behaviours`,
		icon: `ocd.svg`,
	},
	{
		label: `Extreme Worry and/or Concern`,
		value: `Extreme Worry and/or Concern`,
		icon: `easily-frustrated-annoyed.svg`,
	},
	{
		label: `Frequent Negative Thoughts`,
		value: `Frequent Negative Thoughts`,
		icon: `negative-thoughts.svg`,
	},
	{
		label: `Hopeless and Fed-up`,
		value: `Hopeless and Fed-up`,
		icon: `hopeless-and-fed-up.svg`,
	},
	{
		label: `Racing Heart`,
		value: `Racing Heart`,
		icon: `racing-heart.svg`,
	},
	{
		label: `Sad and Tearful`,
		value: `Sad and Tearful`,
		icon: `sad-and-tearful.svg`,
	},
	{
		label: `Self-harming`,
		value: `Self-harming`,
		icon: `self-harm.svg`,
	},
	{
		label: `Negative Thoughts`,
		value: `Negative Thoughts`,
		icon: `negative-thoughts.svg`,
	},
	/*
	{
		label: 'Suicidal thoughts',
		value: 'Suicidal thoughts',
		icon: 'suicide.svg',
	},
	*/
	{
		label: `Struggling to Cope`,
		value: `Struggling to Cope`,
		icon: `struggling-to-cope.svg`,
	},
	{
		label: `Unable to make decisions`,
		value: `Unable to make decisions`,
		icon: `unable-to-make-decisions.svg`,
	},
	{
		label: `Unable to sleep or stay asleep`,
		value: `Unable to sleep or stay asleep`,
		icon: `sleepy.svg`,
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.stress.emotions`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.stress.emotions.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionStressEmotions({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What other feelings, emotions, or behaviours have you been experiencing in addition to Stress?"
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					buttonType="icons"
					value={formData[`mood.stress.emotions`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.stress.emotions.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionStressEmotions.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionStressEmotions.getNextItemKey = (formData) => {
	return (formData[`mood.stress.emotions`] ? `QuestionHealthAffected` : undefined);
};

QuestionStressEmotions.getAllowSubmit = () => false;

export default connectToStore(QuestionStressEmotions);
