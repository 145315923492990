import React from 'react';
import ListSelect from './ListSelect';
import styles from './NumericalSelect.module.css';

function createNumericalOptions(minValue, maxValue) {
	if (minValue < 0) throw new Error(`The minimum value cannot be less than zero`);
	if (maxValue === minValue) throw new Error(`The maximum value cannot be the same as the minimum value`);
	if (maxValue < minValue) throw new Error(`The maximum value cannot be less than the minimum value`);

	const options = [];

	for (let i = minValue; i <= maxValue; i++) {
		const num = String(i);
		options.push({ label: num, value: num });
	}

	return options;
}

function NumericalSelect({ value, minValue, maxValue, minLabel, maxLabel, onChange }) {
	const options = createNumericalOptions(minValue, maxValue);

	return (
		<>
			<ListSelect
				className={styles.numericalSelect}
				mode="single"
				layout="horizontal"
				buttonType="plain"
				value={value}
				options={options}
				onChange={onChange}
			/>
			<div className={styles.labels}>
				<div>{minLabel}</div>
				<div>{maxLabel}</div>
			</div>
		</>
	);
}





export default NumericalSelect;
