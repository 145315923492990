import React from 'react';
import { connectToStore } from './store';
import ActiveItem from './components/ActiveItem';

function App() {
  return (
    <ActiveItem />
  );
}

export default connectToStore(App);
