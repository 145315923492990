function updateFormData(newFormData) {
	return {
		type: `UPDATE_FORM_DATA`,
		payload: { newFormData },
	};
}

export default {
	updateFormData,
};
