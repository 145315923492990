import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import NumericalSelect from '../common/NumericalSelect';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionHealthAffected.module.css';

function updateAnswer(actions, activeItem, formData, newValue) {
	formData[`impact.health`] = newValue;
	actions.updateFormData(formData);
	// actions.goToNextItem(activeItem, formData);
}

function QuestionHealthAffected({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="Emotions influence everyone in different ways. How much has your HEALTH been affected? *"
				/>
				<NumericalSelect
					minValue="1"
					maxValue="5"
					minLabel="Not at all"
					maxLabel="Daily struggle"
					value={formData[`impact.health`]}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue)}
				/>
			</article>
		</section>
	);
}

QuestionHealthAffected.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionHealthAffected.getNextItemKey = (formData) => {
	return (formData[`impact.health`] ? `QuestionWorkAffected` : undefined);
};

QuestionHealthAffected.getAllowSubmit = () => false;

export default connectToStore(QuestionHealthAffected);
