import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import NumericalSelect from '../common/NumericalSelect';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionRelationshipsAffected.module.css';

function updateAnswer(actions, activeItem, formData, newValue) {
	formData[`impact.relationships`] = newValue;
	actions.updateFormData(formData);
	// actions.goToNextItem(activeItem, formData);
}

function QuestionRelationshipsAffected({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="Finally, how about your RELATIONSHIPS? *"
				/>
				<NumericalSelect
					minValue="1"
					maxValue="5"
					minLabel="Not at all"
					maxLabel="Daily struggle"
					value={formData[`impact.relationships`]}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue)}
				/>
			</article>
		</section>
	);
}

QuestionRelationshipsAffected.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionRelationshipsAffected.getNextItemKey = () => undefined;

QuestionRelationshipsAffected.getAllowSubmit = (formData) => {
	return (formData[`impact.relationships`] ? true : false);
};

export default connectToStore(QuestionRelationshipsAffected);
