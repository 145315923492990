import React from 'react';
import styles from './Checkbox.module.css';

function Checkbox({ className, label, hideCheckboxes = false, isChecked, onClick }) {
	return (
		<div data-testid={`checkbox-${label}`} className={`${styles.checkbox} ${isChecked && hideCheckboxes && styles.checkboxChecked} ${className}`} onClick={onClick}>
			<div className={`${styles.squareContainer} ${hideCheckboxes && styles.hideCheckboxes}`}>
				<div data-testid={`checkbox-${label}-square`} className={`${styles.square} ${isChecked && styles.squareChecked}`}></div>
			</div>
			<div className={styles.label}>{label}</div>
		</div>
	);
}

export default Checkbox;
