import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionAnxietyCauses.module.css';

export const OPTIONS = [
	{
		label: 'Bereavement',
		value: 'Bereavement',
		icon: 'bereavement.svg',
	},
	{
		label: 'Bullying or Abuse',
		value: 'Bullying or Abuse',
		icon: 'bullying-or-abuse.svg',
	},
	{
		label: 'Coronavirus',
		value: 'Coronavirus',
		icon: 'coronavirus.svg',
	},
	{
		label: 'Family Issues',
		value: 'Family Issues',
		icon: 'family-problems.svg',
	},
	{
		label: 'Financial Concerns',
		value: 'Financial Concerns',
		icon: 'financial-concerns.svg',
	},
	{
		label: 'Food Relationship Problems',
		value: 'Food Relationship Problems',
		icon: 'eating-disorders.svg',
	},
	{
		label: 'Health Concerns',
		value: 'Health Concerns',
		icon: 'illness-or-injury.svg',
	},
	{
		label: 'Loneliness',
		value: 'Loneliness',
		icon: 'loneliness.svg',
	},
	{
		label: 'Marital Life',
		value: 'Marital Life',
		icon: 'marital-life.svg',
	},
	{
		label: 'New Job',
		value: 'New Job',
		icon: 'new-job.svg',
	},
	{
		label: 'Phobias',
		value: 'Phobias',
		icon: 'phobias.svg',
	},
	{
		label: 'Relationship Problems',
		value: 'Relationship Problems',
		icon: 'relationship-problems.svg',
	},
	{
		label: 'Retiring',
		value: 'Retiring',
		icon: 'retiring.svg',
	},
	{
		label: 'Trauma',
		value: 'Trauma',
		icon: 'trauma.svg',
	},
	{
		label: 'University Pressures',
		value: 'University Pressures',
		icon: 'university-pressure.svg',
	},
	{
		label: 'Work Pressures',
		value: 'Work Pressures',
		icon: 'work-pressures.svg',
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.anxiety.causes`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.anxiety.causes.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionAnxietyCauses({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="Don't worry, it's not unusual to feel nervous, worried or concerned. What is making you feel this way? Please be honest as this will help us find you the best therapy option to suit your needs."
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					buttonType="icons"
					value={formData[`mood.anxiety.causes`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.anxiety.causes.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionAnxietyCauses.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionAnxietyCauses.getNextItemKey = (formData) => {
	return (formData[`mood.anxiety.causes`] ? `QuestionAnxietyEmotions` : undefined);
};

QuestionAnxietyCauses.getAllowSubmit = () => false;

export default connectToStore(QuestionAnxietyCauses);
