import axios from 'axios';
import { canSubmitForm, filterEmptyArrayElements, decodeListValue, getAccessToken } from './utilities';

const IS_PROD = window.location.host === `well-being.lettheinsideout.com`;
export const API_URL = IS_PROD
	? `https://api-production.lettheinsideout.com`
	: `https://api-staging.lettheinsideout.com`;
const FORM_SUBMIT_TIMEOUT = 1000 * 30;

function completeForm(formData, actions) {
	const finalFormData = { ...formData, 'meta.completed': true, 'meta.time.completed': Date.now() };
	actions.updateFormData(finalFormData);
	return finalFormData;
}

function undoFormCompletion(originalFormData, actions) {
	actions.updateFormData(originalFormData);
}

function mapFormDataToOldFormat(formData) {
	const anxietyCauses = decodeListValue(formData[`mood.anxiety.causes`]);
	const anxietyCausesOther = formData[`mood.anxiety.causes.other`];

	const anxietyEmotions = decodeListValue(formData[`mood.anxiety.emotions`]);
	const anxietyEmotionsOther = formData[`mood.anxiety.emotions.other`];

	const depressionCauses = decodeListValue(formData[`mood.depression.causes`]);
	const depressionCausesOther = formData[`mood.depression.causes.other`];

	const depressionEmotions = decodeListValue(formData[`mood.depression.emotions`]);
	const depressionEmotionsOther = formData[`mood.depression.emotions.other`];

	const stressCauses = decodeListValue(formData[`mood.stress.causes`]);
	const stressCausesOther = formData[`mood.stress.causes.other`];

	const stressEmotions = decodeListValue(formData[`mood.stress.emotions`]);
	const stressEmotionsOther = formData[`mood.stress.emotions.other`];

	const addictionTypes = decodeListValue(formData[`mood.addiction.types`]);
	const addictionTypesOther = formData[`mood.addiction.types.other`];

	const addictionCauses = decodeListValue(formData[`mood.addiction.causes`]);
	const addictionCausesOther = formData[`mood.addiction.causes.other`];

	const addictionEmotions = decodeListValue(formData[`mood.addiction.emotions`]);
	const addictionEmotionsOther = formData[`mood.addiction.emotions.other`];

	const moodSelections = filterEmptyArrayElements([
		...anxietyCauses,
		anxietyCausesOther,
		...depressionCauses,
		depressionCausesOther,
		...stressCauses,
		stressCausesOther,
		...addictionTypes,
		addictionTypesOther,
	]);

	const additionalMoodSelections = filterEmptyArrayElements([
		...anxietyEmotions,
		anxietyEmotionsOther,
		...depressionEmotions,
		depressionEmotionsOther,
		...stressEmotions,
		stressEmotionsOther,
		...addictionCauses,
		addictionCausesOther,
	]);

	const addictionMoodSelections = filterEmptyArrayElements([...addictionEmotions, addictionEmotionsOther]);

	return {
		// Old format properties.
		user_id: formData[`individual.id`],
		form_data: {
			individualsEmail: formData[`individual.email`],
			individualsGender: formData[`individual.gender`],
			practitionersGender: formData[`practitioner.gender`],
			practitionersCulture: formData[`practitioner.culture`],
			individualsPhone: formData[`individual.phone`],
			individualsEmployer: formData[`employer.name`],
			individualsMood: formData[`mood.general`],
			moodSelections,
			additionalMoodSelections,
			addictionMoodSelections,
			healthAffected: formData[`impact.health`],
			abilityToWork: formData[`impact.work`],
			relationships: formData[`impact.relationships`],
			individualsGP: formData[`individual.gp`],
		},

		// New format properties.
		userId: formData[`individual.id`],
		timeTaken: Date.now() - formData[`meta.time.started`],
		formData,
	};
}

async function makeRequest(data, correlation_id) {
	const opts = {
		baseURL: API_URL,
		timeout: FORM_SUBMIT_TIMEOUT,
		headers: {
			'x-correlation-id': correlation_id,
			'authorization': `Bearer ${getAccessToken()}`,
		},
		validateStatus: null,
	};
	let req;

	try {
		req = await axios.create(opts).post(`diagnosticForm`, data);
		if (req.status !== 200)
			return [false, req && req.data && req.data.error ? req.data.error.error_message : `Unknown error`];
	} catch (err) {
		return [false, err.message];
	}

	return [true];
}

function closeFormWebView(isFeelingGreat) {
	if (!window.ReactNativeWebView) return;
	const message = JSON.stringify({ data: `complete`, isFeelingGreat });
	window.ReactNativeWebView.postMessage(message);
}

export async function submitForm(activeItem, formData, actions) {
	const canSubmit = canSubmitForm(activeItem, formData);
	if (!canSubmit) throw new Error(`Not allowed to submit form at this point`);

	const finalFormData = completeForm(formData, actions); // Prevent further changes to the form.

	const isFeelingGreat = Boolean(finalFormData[`mood.general`] === `feelingGreat`);
	const payload = mapFormDataToOldFormat(finalFormData);
	const [success, errMsg] = await makeRequest(payload, formData['meta.correlation.id']);

	if (!success) {
		alert(`An error occurred: ${errMsg}`);
		undoFormCompletion(formData, actions);
		return success;
	}

	closeFormWebView(isFeelingGreat);
}
