import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionPractitionersGender.module.css';

export const OPTIONS = [
	{ label: `No preference`, value: `any` },
	{ label: `Female`, value: `female` },
	{ label: `Male`, value: `male` },
];

function updateAnswer(actions, activeItem, formData, newValue) {
	formData[`practitioner.gender`] = newValue;
	actions.updateFormData(formData);
	actions.goToNextItem(activeItem, formData);
}

function QuestionPractitionersGender({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="Which gender therapist would you prefer to speak to? *"
					description="We need this to provide you with relevant matches."
				/>
				<ListSelect
					mode="single"
					layout="vertical"
					buttonType="checkboxes"
					value={formData[`practitioner.gender`]}
					options={OPTIONS}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue)}
				/>
			</article>
		</section>
	);
}

QuestionPractitionersGender.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionPractitionersGender.getNextItemKey = (formData) => {
	return (formData[`practitioner.gender`] ? `QuestionPractitionersCulture` : undefined);
};

QuestionPractitionersGender.getAllowSubmit = () => false;

export default connectToStore(QuestionPractitionersGender);
