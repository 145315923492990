import { getUserId } from '../../lib/utilities';
import { v4 as uuidv4 } from 'uuid';

const INITIAL_STATE = {
	'meta.completed': false,
	'meta.time.started': Date.now(),
	'meta.time.completed': null,
	'meta.correlation.id': uuidv4(),
	'consent.agreement': ``,
	'individual.id': getUserId(),
	'individual.email': ``,
	'individual.phone': ``,
	'individual.gender': ``,
	'individual.country': ``,
	'individual.usState': ``,
	'individual.gp': ``,
	'emergency.contact.name': ``,
	'emergency.contact.phone': ``,
	'emergency.contact.phone.confirmation': ``,
	'practitioner.gender': ``,
	'practitioner.culture': ``,
	'practitioner.language': ``,
	'employer.benefit': ``,
	'employer.name': ``,
	'mood.general': ``,
	'mood.anxiety.causes': ``,
	'mood.anxiety.causes.other': ``,
	'mood.anxiety.emotions': ``,
	'mood.anxiety.emotions.other': ``,
	'mood.depression.causes': ``,
	'mood.depression.causes.other': ``,
	'mood.depression.emotions': ``,
	'mood.depression.emotions.other': ``,
	'mood.stress.causes': ``,
	'mood.stress.causes.other': ``,
	'mood.stress.emotions': ``,
	'mood.stress.emotions.other': ``,
	'mood.addiction.types': ``,
	'mood.addiction.types.other': ``,
	'mood.addiction.causes': ``,
	'mood.addiction.causes.other': ``,
	'mood.addiction.emotions': ``,
	'mood.addiction.emotions.other': ``,
	'impact.health': ``,
	'impact.work': ``,
	'impact.relationships': ``,
};

function updateFormData(state, newFormData) {
	const newState = {
		...state,
		...newFormData,
	};

	Object.keys(newState).forEach(key => {
		if (INITIAL_STATE[key] === undefined) {
			throw new Error(`Form data key "${key}" is not specified in the initial state of the form data reducer`);
		}
	});

	return newState;
}

export default function reducer(state = { ...INITIAL_STATE }, action) {
	const { type, payload } = action;
	const { newFormData } = payload || {};

	switch (type) {
		case `UPDATE_FORM_DATA`:
			return updateFormData(state, newFormData);

		default:
			return state;
	}
}
