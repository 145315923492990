import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionAddictionTypes.module.css';

export const OPTIONS = [
	{
		label: 'Alcohol',
		value: 'Alcohol',
		icon: '',
	},
	{
		label: 'Drugs',
		value: 'Drugs',
		icon: '',
	},
	{
		label: 'Exercise',
		value: 'Exercise',
		icon: '',
	},
	{
		label: 'Gambling',
		value: 'Gambling',
		icon: '',
	},
	{
		label: 'Gaming',
		value: 'Gaming',
		icon: '',
	},
	{
		label: 'Mobile / IT',
		value: 'Mobile / IT',
		icon: '',
	},
	{
		label: 'Over or Under Eating',
		value: 'Over or Under Eating',
		icon: '',
	},
	{
		label: 'Porn',
		value: 'Porn',
		icon: '',
	},
	{
		label: 'Sex',
		value: 'Sex',
		icon: '',
	},
	{
		label: 'Shopping',
		value: 'Shopping',
		icon: '',
	},
	{
		label: 'Self-harming',
		value: 'Self-harming',
		icon: '',
	},
	{
		label: 'Work',
		value: 'Work',
		icon: '',
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.addiction.types`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.addiction.types.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionAddictionTypes({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="Don't worry, it's not unusual to struggle with an addiction. Can you tell us which of the following types of addiction resonate with you? It's OK to tick more than one option and remember all entries are completely confidential."
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					layout="vertical"
					buttonType="checkboxes"
					value={formData[`mood.addiction.types`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.addiction.types.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionAddictionTypes.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionAddictionTypes.getNextItemKey = (formData) => {
	return (formData[`mood.addiction.types`] ? `QuestionAddictionCauses` : undefined);
};

QuestionAddictionTypes.getAllowSubmit = () => false;

export default connectToStore(QuestionAddictionTypes);
