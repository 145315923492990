import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionDepressionCauses.module.css';

export const OPTIONS = [
	{
		label: 'An Addiction',
		value: 'An Addiction',
		icon: 'struggling-with-addiction.svg',
	},
	{
		label: 'Becoming a Parent',
		value: 'Becoming a Parent',
		icon: 'becoming-a-parent.svg',
	},
	{
		label: 'Bereavement',
		value: 'Bereavement',
		icon: 'bereavement.svg',
	},
	{
		label: 'Bullying or Abuse',
		value: 'Bullying or Abuse',
		icon: 'bullying-or-abuse.svg',
	},
	{
		label: 'Coronavirus',
		value: 'Coronavirus',
		icon: 'coronavirus.svg',
	},
	{
		label: 'Eating Problems',
		value: 'Eating Problems',
		icon: 'eating-disorders.svg',
	},
	{
		label: 'Family Problems',
		value: 'Family Problems',
		icon: 'family-problems.svg',
	},
	{
		label: 'Getting Married',
		value: 'Getting Married',
		icon: 'getting-married.svg',
	},
	{
		label: 'Illness or Injury',
		value: 'Illness or Injury',
		icon: 'illness-or-injury.svg',
	},
	{
		label: 'Postnatal Depression',
		value: 'Postnatal Depression',
		icon: 'post-partum-depression.svg',
	},
	{
		label: 'Marital Problems',
		value: 'Marital Problems',
		icon: 'martial-problems.svg',
	},
	{
		label: 'Poor Living Conditions',
		value: 'Poor Living Conditions',
		icon: 'poor-living-standards.svg',
	},
	{
		label: 'Relationship Problems',
		value: 'Relationship Problems',
		icon: 'relationship-problems.svg',
	},
	{
		label: 'Work Pressure',
		value: 'Work Pressure',
		icon: 'work-pressures.svg',
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.depression.causes`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.depression.causes.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionDepressionCauses({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="It's not unusual to feel sad and unhappy. Don't worry we're here to help you. What is making you feel this way? Please be honest as this will help us find you the best coach to suit your needs. Remember, your inputs will remain completely confidential."
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					buttonType="icons"
					value={formData[`mood.depression.causes`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.depression.causes.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionDepressionCauses.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionDepressionCauses.getNextItemKey = (formData) => {
	return (formData[`mood.depression.causes`] ? `QuestionDepressionEmotions` : undefined);
};

QuestionDepressionCauses.getAllowSubmit = () => false;

export default connectToStore(QuestionDepressionCauses);
