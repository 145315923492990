import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import Input from '../common/Input';
import { extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionEmergencyContactName.module.css';

function updateAnswer(actions, formData, evt) {
	formData[`emergency.contact.name`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionEmergencyContactName({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What is the name of your emergency contact? *"
					description="We require emergency contact details so we can alert your next of kin in the event of an emergency."
				/>
				<Input
					name="emergency-contact"
					value={formData[`emergency.contact.name`]}
					type="text"
					placeholder="Type their name here..."
					onChange={(evt) => updateAnswer(actions, formData, evt)}
					onReturnKey={() => actions.goToNextItem(activeItem, formData)}
				/>
			</article>
		</section>
	);
}

QuestionEmergencyContactName.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionEmergencyContactName.getNextItemKey = (formData) => {
	return (formData[`emergency.contact.name`].trim() ? `QuestionEmergencyContactPhone` : undefined);
};

QuestionEmergencyContactName.getAllowSubmit = () => false;

export default connectToStore(QuestionEmergencyContactName);
