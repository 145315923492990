import React, { useRef, forwardRef } from 'react';
import { connectToStore } from '../../store';
import ListSelect from '../common/ListSelect';
import { extractPrevItemKeyFromPath, sendDiagnosticStartedAction } from '../../lib/utilities';
import styles from './BlurbConsent.module.css';

const OPTIONS = [
	{
		label: `I Agree`,
		value: `agree`,
	},
	{
		label: `I Disagree`,
		value: `disagree`,
	}
];

function updateAnswer(actions, activeItem, formData, newValue, disagreeMsgEl) {
	formData[`consent.agreement`] = newValue;
	actions.updateFormData(formData);

	if (newValue === `agree`) {
		// User started the Wellbeing form so we're saving this action
		if (formData['meta.correlation.id'] && formData['individual.id']) {
			sendDiagnosticStartedAction({
				action: 'DIAGNOSTIC_STARTED',
				user_id_diagnostic: formData['individual.id'],
				user_type_diagnostic: 'patient'
			}, formData['meta.correlation.id']);
		}

		actions.goToNextItem(activeItem, formData);
	} else {
		disagreeMsgEl.current.scrollIntoView({ behavior: `smooth` });
	}
}

const DisagreeMessage = forwardRef(({ hasExplicitlyDisagreed }, ref) => {
	return (
		<div ref={ref} className={`${styles.disagreeMessage} ${hasExplicitlyDisagreed && styles.disagreeMessageVisible}`}>
			<p>We're very sorry to hear that you do not consent to us collecting your Health data.</p>
			<p>Unfortunately without your consent we are unable to grant you access to the InsideOut network or continue with the mental health diagnostic at this time.</p>
		</div>
	);
});

function BlurbConsent({ state, actions, goNext }) {
	const { activeItem, formData } = state;
	const disagreeMsgEl = useRef(null);
	const hasExplicitlyDisagreed = formData[`consent.agreement`] === `disagree`;

	return (
		<section>
			<article>
				<p>Before we get started we need you to consent to our collection and use of your Mental Health Information ("Health Data"). You understand that we may share your Health Data with our therapists, health experts and third parties such as health professionals or emergency services, next of kin, or friends where we believe that your life is at risk or you are a risk to somebody else.</p>
				<p>We use a video conferencing service to facilitate your counselling sessions if required. We have contracts in place to ensure your data is protected in line with the high standards of the EU. Please see our privacy policy for more details.</p>
				<p>InsideOut does not offer emergency or immediate crisis interventions. If you feel you are at risk of hurting yourself or someone else please call the Samaritans on 116 123 or the emergency services on 999.</p>
				<p>*You can withdraw your consent at any time, but if you do so, you will not be able to use the app or book any/further sessions with a mental health care professional. *</p>
				<ListSelect
					className={styles.checkboxes}
					mode="single"
					layout="vertical"
					buttonType="checkboxes"
					value={formData[`consent.agreement`]}
					options={OPTIONS}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, disagreeMsgEl)}
				/>
				<DisagreeMessage hasExplicitlyDisagreed={hasExplicitlyDisagreed} ref={disagreeMsgEl} />
			</article>
		</section>
	);
}

BlurbConsent.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

BlurbConsent.getNextItemKey = (formData) => {
	const hasAgreed = (formData[`consent.agreement`] === `agree`);
	const hasUserId = Boolean(formData[`individual.id`]);

	switch (true) {
		case (hasAgreed && !hasUserId): return `QuestionIndividualsEmail`;
		case (hasAgreed && hasUserId): return `QuestionIndividualsPhone`;
		default: return undefined;
	}
};

BlurbConsent.getAllowSubmit = () => false;

export default connectToStore(BlurbConsent);
