import React, { forwardRef } from 'react';
import QuestionCopy from './QuestionCopy';
import Input from './Input';
import styles from './OtherInput.module.css';

const OtherInput = forwardRef(({ value, onChange }, ref) => {
	return (
		<div className={styles.otherInput} ref={ref}>
			<QuestionCopy title="Is there anything else you want to add?" />
			<Input
				value={value}
				type="text"
				placeholder="Type your answer here..."
				onChange={onChange}
			/>
		</div>
	);
});

export default OtherInput;
