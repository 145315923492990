import React from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import Input from '../common/Input';
import { extractPrevItemKeyFromPath, formatPhoneNumber } from '../../lib/utilities';
import styles from './QuestionEmergencyContactPhone.module.css';

function updateAnswer(actions, formData, evt) {
	formData[`emergency.contact.phone`] = formatPhoneNumber(evt.target.value);
	actions.updateFormData(formData);
}

function updateAnswerConfirmation(actions, formData, evt) {
	formData[`emergency.contact.phone.confirmation`] = formatPhoneNumber(evt.target.value);
	actions.updateFormData(formData);
}

function QuestionEmergencyContactPhone({ state, actions }) {
	const { activeItem, formData } = state;

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="What is the phone number of your emergency contact? *"
					description="We require emergency contact details so we can alert your next of kin in the event of an emergency. It needs to be different than your phone number."
				/>
				<Input
					value={formData[`emergency.contact.phone`]}
					type="tel"
					placeholder="Type their phone number here..."
					onChange={(evt) => updateAnswer(actions, formData, evt)}
				/>
				<QuestionCopy title="Please confirm their phone number *" />
				<Input
					value={formData[`emergency.contact.phone.confirmation`]}
					type="tel"
					placeholder="Type their phone number here..."
					onChange={(evt) => updateAnswerConfirmation(actions, formData, evt)}
					onReturnKey={() => actions.goToNextItem(activeItem, formData)}
				/>
			</article>
		</section>
	);
}

QuestionEmergencyContactPhone.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionEmergencyContactPhone.getNextItemKey = (formData) => {
	const userPhone = formData[`individual.phone`].trim();
	const emergencyPhone1 = formData[`emergency.contact.phone`].trim();
	const emergencyPhone2 = formData[`emergency.contact.phone.confirmation`].trim();
	const isSameEmergencyNumber = emergencyPhone1 && emergencyPhone2 && emergencyPhone1 === emergencyPhone2;
	const isDifferentFromIndividualNumber = userPhone !== emergencyPhone1;
	return (isSameEmergencyNumber && isDifferentFromIndividualNumber ? `QuestionPractitionersGender` : undefined);
};

QuestionEmergencyContactPhone.getAllowSubmit = () => false;

export default connectToStore(QuestionEmergencyContactPhone);
