export function mapStateToProps(state) {
	return { state };
}

function dispatchAction(dispatch, createActionFn, ...args) {
	const action = createActionFn(...args);
	if (action) dispatch(action);
}

export function mapDispatchToProps(dispatch, actions) {
	const actionFunctions = Object.entries(actions).reduce((acc, [actionName, createActionFn]) => {
		acc[actionName] = (...args) => dispatchAction(dispatch, createActionFn, ...args);
		return acc;
	}, {});

	return { actions: actionFunctions };
}
