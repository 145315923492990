import React, { useRef } from 'react';
import { connectToStore } from '../../store';
import QuestionCopy from '../common/QuestionCopy';
import ListSelect from '../common/ListSelect';
import OtherInput from '../common/OtherInput';
import { listValueContainsOther, extractPrevItemKeyFromPath } from '../../lib/utilities';
import styles from './QuestionAddictionCauses.module.css';
export const OPTIONS = [
	{
		label: `Bereavement`,
		value: `Bereavement`,
		icon: ``,
	},
	{
		label: `Bullying`,
		value: `Bullying`,
		icon: ``,
	},
	{
		label: 'Coronavirus',
		value: 'Coronavirus',
		icon: '',
	},
	{
		label: `Family Challenges`,
		value: `Family Challenges`,
		icon: ``,
	},
	{
		label: `Friendship Problems`,
		value: `Friendship Problems`,
		icon: ``,
	},
	{
		label: `Loneliness`,
		value: `Loneliness`,
		icon: ``,
	},
	{
		label: `Low Self-Worth/Confidence`,
		value: `Low Self-Worth/Confidence`,
		icon: ``,
	},
	{
		label: `Marital Problems`,
		value: `Marital Problems`,
		icon: ``,
	},
	{
		label: `Money Concerns`,
		value: `Money Concerns`,
		icon: ``,
	},
	{
		label: `Negative Thoughts`,
		value: `Negative Thoughts`,
		icon: ``,
	},
	/*
	{
		label: 'Suicidal thoughts',
		value: 'Suicidal thoughts',
		icon: '',
	},
	*/
	{
		label: `Relationship Challenges`,
		value: `Relationship Challenges`,
		icon: ``,
	},
	{
		label: `Trauma or Accident`,
		value: `Trauma or Accident`,
		icon: ``,
	},
	{
		label: `University Pressure`,
		value: `University Pressure`,
		icon: ``,
	},
	{
		label: `Work Pressure`,
		value: `Work Pressure`,
		icon: ``,
	},
];

function updateAnswer(actions, activeItem, formData, newValue, otherInputEl) {
	formData[`mood.addiction.causes`] = newValue;
	actions.updateFormData(formData);
	if (listValueContainsOther(newValue)) otherInputEl.current.querySelector(`input`).focus();
}

function updateAnswerOther(actions, formData, evt) {
	formData[`mood.addiction.causes.other`] = evt.target.value;
	actions.updateFormData(formData);
}

function QuestionAddictionCauses({ state, actions }) {
	const { activeItem, formData } = state;
	const otherInputEl = useRef(null);

	return (
		<section className={styles.question}>
			<article>
				<QuestionCopy
					questionNum={activeItem.questionNum}
					title="To help us find the most relevant therapy option, can you identify which factors trigger and/or cause your addiction(s)?"
					description="Select as many options as you like."
				/>
				<ListSelect
					mode="multi"
					layout="vertical"
					buttonType="checkboxes"
					value={formData[`mood.addiction.causes`]}
					options={OPTIONS}
					otherOption={true}
					skipOption={true}
					onChange={(newValue) => updateAnswer(actions, activeItem, formData, newValue, otherInputEl)}
				/>
				<OtherInput ref={otherInputEl} value={formData[`mood.addiction.causes.other`]} onChange={(evt) => updateAnswerOther(actions, formData, evt)} />
			</article>
		</section>
	);
}

QuestionAddictionCauses.getPrevItemKey = (_, itemPath) => extractPrevItemKeyFromPath(itemPath);

QuestionAddictionCauses.getNextItemKey = (formData) => {
	return (formData[`mood.addiction.causes`] ? `QuestionAddictionEmotions` : undefined);
};

QuestionAddictionCauses.getAllowSubmit = () => false;

export default connectToStore(QuestionAddictionCauses);
